<template>
  <div class="about-me">
    <center>
      No Page exists with this URL.
      <p>
        To go back to the Swap, click
        <router-link to="/swap">here</router-link>.
      </p>
    </center>
  </div>
</template>
<style>
.about-me {
  padding-top: 4rem;
  margin-top: 6rem;
}
</style>
