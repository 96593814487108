import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      swapDialog: {
        bool: false,
        DialnumAdd: [
          "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
          "0x765277EebeCA2e31912C9946eAe1021199B39C61",
        ],
      },
      pathExists: true,
      watchInputs: [false, false],
      swapWatchInp: true,
      path: [
        "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
        "0x765277EebeCA2e31912C9946eAe1021199B39C61",
      ],
      symbolsPath: ["WKAVA", "DAI"],
      swapTokenSymbol: ["WKAVA", "DAI"],
      amountToken0: null,
      amountToken1: null,
      tokenBalText: [0, 0],
      tokenReserves: [0, 0],
      insufficientBal: false,
      slippage: 15,
      deadline: 10,
      convertRate: 0,
      insuffLiq: false,
      dispPriceImp: false,
      priceImpVal: null,
      priceImpValBack: null,
      WrapUnwrap: null,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
