import { createApp, defineAsyncComponent } from "vue";

import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import { VueDapp } from "vue-dapp";

import "./assets/styles.css";

import BaseGear from "./components/UI/BaseGear.vue";
import WalletConnect from "./components/UI/WalletConnect.vue";
import BaseDialog from "./components/UI/BaseDialog.vue";
const BaseSpinner = defineAsyncComponent(() =>
  import("./components/UI/BaseSpinner.vue")
);
const ShowAccDetails = defineAsyncComponent(() =>
  import("./components/UI/ShowAccDetails.vue")
);

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueDapp);

app.component("show-acc-details", ShowAccDetails);
app.component("base-spinner", BaseSpinner);
app.component("base-gear", BaseGear);
app.component("BaseDialog", BaseDialog);
app.component("WalletConnect", WalletConnect);

app.mount("#app");
