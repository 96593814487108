import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      isLoading: false,
      displayConnectWalletButton: false,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
