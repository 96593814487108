<script>
import { defineComponent, onBeforeMount } from "vue";
import {
  useBoard,
  useEthers,
  useWallet,
  useEthersHooks,
  displayChainName,
  shortenAddress,
  MetaMaskConnector,
  WalletConnectConnector,
  CoinbaseWalletConnector,
  SafeConnector,
} from "vue-dapp";
import { useStore, mapGetters } from "vuex";
import { ethers } from "ethers";
import { getNetworkFromChainId } from "../../ethereumFunctions";
import web3 from "../../../ethereum/web3";
import swal from "sweetalert";

const { wallet, disconnect } = useWallet();
const { address } = useEthers();
const { onActivated } = useEthersHooks();
const { open } = useBoard();

export default defineComponent({
  setup() {
    const store = useStore();
    const setAccount = (account) => store.dispatch("setAccount", account);

    const infuraId = "";

    let connectors = [
      new MetaMaskConnector({
        appUrl: "http://localhost:8080",
      }),
      new WalletConnectConnector({
        qrcode: true,
        rpc: {
          2222: "https://evm.kava.io",
          // 42161: "https://arb1.arbitrum.io/rpc",
        },
      }),
      new CoinbaseWalletConnector({
        appName: "Selfex",
        jsonRpcUrl: `https://mainnet.infura.io/v3/${infuraId}`,
      }),
    ];

    onBeforeMount(async () => {
      const safe = new SafeConnector();
      if (await safe.isSafeApp()) {
        connectors = [safe];
      }
    });

    onActivated(() => {
      store.dispatch("activateAccount");
    });

    return {
      connectors,
      displayChainName,
      shortenAddress,
      open,
      disconnect,
      setAccount,
      store,
    };
  },

  data() {
    return {
      wallet,
      address,
      displayDropdown: ["none", "none"],
      seeIfOpen: false,
      getNetworkFromChainId,
      isDark: window?.matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },

  async mounted() {
    window.onclick = () => {
      if (
        this.displayDropdown[0] === "block" ||
        this.displayDropdown[1] === "block"
      ) {
        if (this.seeIfOpen === false) {
          this.seeIfOpen = true;
        } else {
          this.seeIfOpen = false;
          this.displayDropdown = ["none", "none"];
        }
      }
    };
    if (window.ethereum.isConnected()) {
      const chainId = await web3.eth.getChainId();
      this.checkNetwork(chainId);
    }
    window.ethereum.on("connect", async () => {
      const chainId = await web3.eth.getChainId();
      this.checkNetwork(chainId);
    });
    window.ethereum.on("chainChanged", (hexChainId) => {
      const chainId = web3.utils.hexToNumber(hexChainId);
      this.checkNetwork(chainId);
    });
  },

  methods: {
    showOrHideDropdown(ind) {
      if (this.displayDropdown[ind] === "none") {
        this.displayDropdown[ind] = "block";
      } else {
        this.displayDropdown[ind] = "none";
      }
    },
    showAccDialog() {
      this.$store.state.showAccDialog = true;
    },
    async setAccountDetails() {
      if (!this.store.state.disconnect) {
        const accounts = await web3.eth.getAccounts();
        const balance = await web3.eth.getBalance(accounts[0]);
        const chainId = await web3.eth.getChainId();
        const latestBlock = await web3.eth.getBlockNumber();
        const provider = web3.eth.currentProvider;
        this.store.state.walletConnect.displayConnectWalletButton = true;
        this.setAccount({
          account: accounts[0],
          balance: parseFloat(web3.utils.fromWei(balance, "ether")).toFixed(4),
          chainId: chainId,
          latestBlock: latestBlock,
          isMetaMask: provider.isMetaMask,
        });
        this.$store.dispatch("registerExistingLiquidity");
        this.walletConnectInitializations();
      }
    },
    async checkNetwork(networkChainId) {
      if (networkChainId !== 2222) {
        swal({
          text: "Kindly change to 'KAVA' network to use the app!",
          button: {
            text: "Change Network",
            closeModal: false,
          },
        }).then(() => {
          this.changeNetwork(2222);
          swal.close();
        });
      } else {
        this.setAccountDetails();
      }
    },
    async changeNetwork(chainId) {
      const hex_chainId = ethers.utils.hexValue(chainId);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: hex_chainId }],
        });
      } catch (error) {
        if (error.code === 4902 && chainId === 2222) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x8ae",
                rpcUrls: ["https://evm.kava.io"],
                chainName: "KAVA EVM",
              },
            ],
          });
        }
      }
    },
    walletConnectInitializations() {
      this.$store.dispatch("displayMaxTokenBalance", {
        add: this.swapDialogVars.DialnumAdd[0],
        ind: 0,
        marker: true,
      });
      this.$store.dispatch("displayMaxTokenBalanceLiq", {
        add: this.liqDialogVal.DialnumAdd[0],
        ind: 0,
      });
      this.$store.dispatch("displayMaxTokenBalance", {
        add: this.swapDialogVars.DialnumAdd[1],
        ind: 1,
        marker: true,
      });
      this.$store.dispatch("displayMaxTokenBalanceLiq", {
        add: this.liqDialogVal.DialnumAdd[1],
        ind: 1,
      });
      // this.$store.state.symLP = [];
      this.$store.dispatch("displayReservesSwap");
      this.$store.dispatch("displayReservesPool");
      // this.$store.dispatch("conversionRateSwap");
    },
  },

  props: {
    showOnlyButton: { type: Boolean, default: false },
  },

  computed: {
    active() {
      return this.store.state.disconnect;
    },
    ...mapGetters({
      swapDialogVars: "getSwapDialog",
      liqDialogVal: "getLiqDialog",
    }),
  },

  watch: {
    active() {
      this.setAccountDetails();
    },
  },
});
</script>

<template>
  <div v-if="showOnlyButton">
    <button
      v-if="!this.$store.state.account0"
      @click="open()"
      :disabled="wallet.status === 'connecting'"
    >
      {{ wallet.status === "connecting" ? "Connecting..." : "Connect Wallet" }}
    </button>
  </div>
  <div v-else class="wallet-connect-header-wrapper">
    <!--
    <div class="activate-details">
      <div class="app-dropdown">
        <div class="app-dropdown-trigger" @click="showOrHideDropdown(0)">
          <div class="network-name">
            <img :src="getNetworkFromChainId($store.state.network).icon" />
            {{ getNetworkFromChainId($store.state.network).name }}
          </div>
          <svg height="24" viewBox="0 96 960 960" width="24">
            <path
              d="M480 684.077 267.692 471.769 296 443.461l184 184 184-184 28.308 28.308L480 684.077Z"
            />
          </svg>
        </div>
        <div
          class="app-dropdown-content"
          :style="{ display: displayDropdown[0] }"
        >
          <div @click="changeNetwork(1)" class="app-dropdown-item">
            <div class="network-name">
              <img :src="getNetworkFromChainId(1).icon" />
              {{ getNetworkFromChainId(1).name }}
            </div>
          </div>
          <div @click="changeNetwork(42161)" class="app-dropdown-item">
            <div class="network-name">
              <img :src="getNetworkFromChainId(42161).icon" />
              {{ getNetworkFromChainId(42161).name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="network-name balance" v-if="this.$store.state.account0">
      <img :src="getNetworkFromChainId($store.state.network).icon" />
      {{ $store.state.balance }}
      {{ getNetworkFromChainId($store.state.network).name }}
    </div>
    <div>
      <button v-if="this.$store.state.account0" @click="showAccDialog()">
        <div v-if="this.$store.state.isMetaMask" class="address-wallet-icon">
          <img src="../../assets/svg/metamaskIcon.svg" />
        </div>
        {{ shortenAddress(this.$store.state.account0) }}
        <img
          class="expand-arrow"
          src="https://img.icons8.com/ios-glyphs/30/000000/expand-arrow--v1.png"
        />
      </button>
      <button v-else @click="open()" :disabled="wallet.status === 'connecting'">
        {{
          wallet.status === "connecting" ? "Connecting..." : "Connect Wallet"
        }}
      </button>
    </div>
  </div>
  <vd-board :connectors="connectors" :dark="isDark" />
</template>

<style>
.address-wallet-icon {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.25rem;
  width: 24px;
  height: 24px;
}
.address-wallet-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallet-connect-header-wrapper,
.activate-details {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.activate-details .app-dropdown-content {
  width: 200px;
}
.activate-details p {
  margin: 0;
}
.balance {
  white-space: nowrap;
}
.network-name {
  display: flex;
  white-space: nowrap;
}
.network-name img:not(.expand-arrow) {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 0.5rem;
}
.walletconnect-qrcode__base {
  background-color: var(--background-backdrop);
}
.walletconnect-modal__header,
.walletconnect-modal__close__wrapper {
  position: initial;
}
.walletconnect-modal__header {
  margin-bottom: 1rem;
}
.walletconnect-modal__header p {
  color: #3b99fc;
}
.walletconnect-qrcode__image {
  display: block;
  width: calc(100% - 60px);
}
</style>
