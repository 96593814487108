<template>
  <div class="app-layout">
    <TheHeader></TheHeader>
    <BaseDialog :showModal="$store.state.operationUnderProcess">
      <base-spinner />
    </BaseDialog>
    <main class="app-content">
      <router-view></router-view>
    </main>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
const { ethereum } = window;

import { mapGetters } from "vuex";
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import * as COINS from "./constants/coins.js";

export default {
  components: { TheHeader, TheFooter },
  data() {
    return {};
  },
  beforeMount() {
    // if (!localStorage.getItem("coins")) {
    //   // console.log(localStorage.getItem("coins"));
    //   localStorage.setItem("coins", JSON.stringify(COINS.GÖRLICoins));
    // }
    localStorage.setItem("coins", JSON.stringify(COINS.GÖRLICoins));
  },
  mounted() {
    if (!localStorage.getItem("count")) {
      localStorage.removeItem("allPairs");
      localStorage.removeItem("userPairs");
      localStorage.removeItem("coins");
      console.log(this.$store.state.count);
      localStorage.setItem("count", JSON.stringify(this.$store.state.count));
    }
    if (
      !localStorage.getItem("allPairs") ||
      !localStorage.getItem("userPairs")
    ) {
      this.$store.state.loadAllPairsByFetch = true;
    }
    ethereum.on("accountsChanged", () => {
      localStorage.removeItem("userPairs");
      // this.$store.dispatch("registerExistingLiquidity");
      // console.log("new Account ->", accounts);
      // this.onConnect();
      // if (this.$route.path === "/swap" || this.$route.path === "/pool") {
      // if (this.$route.path === "/swap") {
      //   //lines 28-39 are just being used because the app doesn't use the WebHash mode.
      //   this.$router.push("/swap");
      // } else {
      //   this.$router
      //     .push("/")
      //     .then(() => {
      //       this.$router.push("/pool");
      //     })
      //     .catch((err) => {
      //       console.log("error->>>", err);
      //     });
      // }
      this.$router.go(); //If Hash mode is used this can be used.
      // } else {
      //   this.$router.push("/");
      //   if (this.$route.path !== "/") {
      //     alert("You are getting redirected to the home page!");
      //   }
      // }
    });
  },
  computed: {
    ...mapGetters({
      swapDialogVars: "getSwapDialog",
      liqDialogVal: "getLiqDialog",
    }),
  },
  methods: {},
  watch: {
    "$store.state.coins": {
      handler(newVal) {
        localStorage.setItem("coins", JSON.stringify(newVal));
      },
      deep: true,
    },
    "$store.state.allPairsForGraph": {
      handler(newVal) {
        localStorage.setItem("allPairs", JSON.stringify(newVal));
      },
      deep: true,
    },
    "$store.state.allPairs": {
      handler(newVal) {
        localStorage.setItem("userPairs", JSON.stringify(newVal));
      },
      deep: true,
    },
    // "$store.state.allPairs": {
    //   handler(newVal) {
    //     localStorage.setItem("pairs", JSON.stringify(newVal));
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style>
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background: url("./assets/images/BannerBG.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
}

.app-content {
  flex: 1;
  padding: 2.5rem 0;
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}
</style>
